.logo-background {
    background: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    margin: auto;
    box-shadow: 0px 0px 5px $black;
    backdrop-filter: blur( 2.5px );
    -webkit-backdrop-filter: blur( 2.5px );
    .logo {
        height: 54px;
        width: 54px;
        z-index: 100;
        &:hover {
            cursor: pointer;
            opacity: 0.75;
            transition: 0.5s ease-in-out;
        }
    }
}
